import AppContext from "context/AppContext";
import { LanguageContext } from "context/LanguageContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Hero() {
  const { t } = useTranslation();
  const { data } = useContext(AppContext);

  const { language } = useContext(LanguageContext);

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col h-full w-full">
        <div className="hidden md:block z-[-2] h-[100%] bg-[url('https://ryancv-react.netlify.app/images/bg.jpg')] bg-cover shadow-lg dark:shadow-md dark:shadow-gray-700"></div>
        {/* <div className="h-[170%] bg-[url('assets/images/hero-bg1.jpg')] bg-cover"></div> */}
        <div className="flex flex-col md:items-center gap-3 md:gap-6 py-3 px-6 md:py-6 md:-mt-36">
          <div className="flex md:flex-col items-center gap-3">
            <div className="relative hidden md:block">
              <div className="z-[-1] absolute top-0.5 md:top-1 right-1 md:right-2 w-12 h-12 md:w-40 md:h-40 bg-primary opacity-[50%] rounded-full shadow-lg dark:shadow-md dark:shadow-gray-700  "></div>
              <img
                src={data?.info?.image}
                alt=""
                className="z-20 w-12 h-12 md:w-40 md:h-40 object-cover rounded-full shadow-lg dark:shadow-md dark:shadow-gray-700   border-2 border-neutra2"
              />
            </div>
            <div className="grid md:text-center md:gap-2 md:pt-6">
              <h1 className="font-bold text-xl md:text-3xl">
                {data?.info?.firstName + " " + data?.info?.lastName}
              </h1>
              <span className="flex md:justify-center text-sm md:text-lg font-medium uppercase text-primary">
                {data?.info?.role}
              </span>
            </div>
          </div>
          <div className="flex items-center md:justify-center gap-3">
            <a
              target="_blank"
              href={data?.info?.contact?.facebook}
              className="hover:text-primary text-xl duration-75"
            >
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a
              target="_blank"
              href={data?.info?.contact?.linkedin}
              className="hover:text-primary text-xl duration-75"
            >
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a
              target="_blank"
              href={data?.info?.contact?.whatsapp}
              className="hover:text-primary text-xl duration-75"
            >
              <i className="fa-brands fa-whatsapp"></i>
            </a>
            <a
              target="_blank"
              href={data?.info?.contact?.phone}
              className="hover:text-primary text-xl duration-75"
            >
              <i className="fa-solid fa-square-phone"></i>
            </a>
            <a
              target="_blank"
              href={data?.info?.contact?.github}
              className="hover:text-primary text-xl duration-75"
            >
              <i className="fa-brands fa-github"></i>
            </a>
            {/* <a
              href={data?.info?.contact?.email}
              className="hover:text-primary text-xl duration-75"
            >
              <i className="fa-brands fa-google"></i>
            </a> */}
            <Link
              to="/contact"
              className="hover:text-primary text-xl duration-75"
            >
              <i className="fa-brands fa-google"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="grid my-auto grid-cols-2 divide-x-2 dark:divide-gray-700 border-t-2 dark:border-gray-700">
        <a
          target="_blank"
          href={
            language === "fr" ? "/resume-file_fr.pdf" : "/resume-file_en.pdf"
          }
          className="flex items-center justify-center gap-2 text-center text-sm font-semibold uppercase hover:text-primary p-3 md:py-6"
        >
          <i className="fa fa-download"></i>
          <span className="">{t("download_cv")}</span>
          {/* <span className="md:hidden">Resume</span> */}
        </a>
        <a
          target="_blank"
          href="https://calendly.com/zeroualihamza/discovery-call"
          className="flex items-center justify-center gap-2 text-center text-sm font-semibold uppercase hover:text-primary p-3 md:py-6"
        >
          <i className="fa fa-video"></i>
          <span className="">{t("schedule_meet")}</span>
          {/* <span className="md:hidden">Meet</span> */}
        </a>
      </div>
    </div>
  );
}
