import React from "react";

const Input = React.forwardRef<HTMLButtonElement, any>(
  (
    {
      id,
      className,
      placeholder,
      type,
      value,
      name,
      onChange,
      disabled,
      autoComplete,
      size,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="">
        <input
          ref={ref}
          id={id}
          className={`bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg w-full ${
            size === "sm" && "text-sm px-3 py-1"
          } ${size === "md" && "text-md px-3 py-2"} ${
            size === "lg" && "text-lg px-5 py-2"
          } ${className}`}
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          autoComplete={autoComplete}
          {...rest}
        />
      </div>
    );
  }
);

export default Input;
