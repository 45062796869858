import Button from "components/ui/Button";
import Input from "components/ui/Input";
import TextArea from "components/ui/TextArea";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { useForm, SubmitHandler } from "react-hook-form";
import SectionTitle from "components/atoms/SectionTitle";
import PageTitle from "components/atoms/PageTitle";
import { useTranslation } from "react-i18next";

interface IForm {
  name: string;
  email: string;
  // phone: string;
  message: string;
}

export default function Contact() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<IForm>();
  const onSubmit: SubmitHandler<IForm> = (data) => {
    console.log(data);
    setIsLoading(true);
    emailjs.send("service_x9m47bw", "template_6czo2jt", {
        ...data,
      })
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          console.log("FAILED...", error);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    emailjs.init({
      publicKey: "LugGyVpfkfTcNo3cd",
      // Do not allow headless browsers
      blockHeadless: true,
      blockList: {
        // Block the suspended emails
        // list: ["foo@emailjs.com", "bar@emailjs.com"],
        // The variable contains the email address
        watchVariable: "from_email",
      },
      limitRate: {
        // Set the limit rate for the application
        id: "app",
        // Allow 1 request per 10s
        throttle: 10000,
      },
    });
  }, []);

  return (
    <div className="flex flex-col gap-6 w-full h-full" id="form">
      <PageTitle title={t("menu.contact")} />
      <div className="grid place-items-center w-full h-full my-auto">
        <div className="grid gap-6">
          <h2 className="text-2xl font-bold dark:text-white text-center">
            {t("leave_message")}
          </h2>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="contactForm"
            className="grid gap-3 px-3 md:px-20"
          >
            <Input
              {...register("name")}
              type={"text"}
              name={"name"}
              placeholder={"Your name"}
              className={""}
            />
            <Input
              {...register("email")}
              type={"email"}
              name={"email"}
              placeholder={"Your email address"}
              className={""}
            />
            {/* <Input
          {...register("phone")}
          type={"text"}
          name={"phone"}
          placeholder={"Your phone"}
          className={""}
        /> */}
            <TextArea
              {...register("message")}
              cols={30}
              rows={5}
              name={"message"}
              placeholder={"Write your message..."}
            />
            <Button isAction type="submit" className="w-full mt-3">
              {isLoading ? "Loading ..." : t("send")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
