import { createContext, useState, ReactNode } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishTranslation from "context/translations/en.json";
import frenchTranslation from "context/translations/fr.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: englishTranslation,
    },
    fr: {
      translation: frenchTranslation,
    },
  },
  lng: localStorage.getItem("lang") || "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;

interface LanguageContextType {
  language: string;
  changeLanguage: (lang: string) => void;
}

export const LanguageContext = createContext<Partial<LanguageContextType>>({});

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState(i18n.language);

  function changeLanguage(lang: string) {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
