import { NavLink } from "react-router-dom";
import LanguageSelector from "./atoms/LanguageSelector";
import ThemeSelector from "./atoms/ThemeSelector";
import { useTranslation } from "react-i18next";

export default function Sidebar() {
  const { t } = useTranslation();
  const menu = [
    {
      name: t("menu.home"),
      icon: "house-chimney",
      link: "/",
      end: true,
    },
    {
      name: t("menu.about"),
      icon: "info-circle",
      link: "/about",
      end: false,
    },
    {
      name: t("menu.resume"),
      icon: "id-badge",
      link: "/resume",
      end: false,
    },
    {
      name: t("menu.portfolio"),
      icon: "briefcase",
      link: "/portfolio",
      end: false,
    },
    // {
    //   name: "Contact",
    //   icon: "envelope",
    //   link: "https://calendar.notion.so/meet/hamzazerouali",
    //   end: false,
    // },
  ];

  return (
    <div className="h-full md:pt-10">
      <div className="hidden md:flex flex-col items-center gap-3 duration-75">
        <div className="z-[2] grid place-items-center w-full bg-gray-100/70 dark:bg-black/10 dark:bg-gray-800/50 backdrop-blur-lg l rounded-xl shadow-lg dark:shadow-md dark:shadow-gray-700  border-2 border-gray-200 dark:border-gray-700 duration-75">
          <ThemeSelector />
        </div>
        <div className="grid items-center bg-gray-100/70 dark:bg-black/10 backdrop-blur-lg l rounded-xl shadow-lg dark:shadow-md dark:shadow-gray-700  border-2 border-gray-200 dark:border-gray-700 duration-75">
          <div className="grid divide-y-2 dark:divide-gray-700 divide-primary-100">
            {menu.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  end={item?.end}
                  to={item?.link || "#"}
                  // onClick={window.innerWidth < 500 ? toggleSidebar : () => {}}
                  className={({ isActive, isPending }) =>
                    `grid place-items-center gap-0 px-4 py-3 duration-75 ${
                      isActive ? "text-primary" : "hover:text-primary"
                    }`
                  }
                >
                  {item?.icon && (
                    <i className={`fa fa-${item?.icon} text-lg mr-0 mb-1 `} />
                  )}
                  <span className="text-center text-xs font-semibold">
                    {item?.name}
                  </span>
                </NavLink>
              );
            })}
          </div>
        </div>
        <div className="z-[2] grid place-items-center w-full bg-gray-100/70 dark:bg-black/10 dark:bg-gray-800/50 backdrop-blur-lg l rounded-xl shadow-lg dark:shadow-md dark:shadow-gray-700  border-2 border-gray-200 dark:border-gray-700 duration-75">
          <LanguageSelector />
        </div>
      </div>

      <div className="md:hidden grid grid-cols-4 gap-0 w-full bg-gray-100/70 dark:bg-black/10 shadow-lg dark:shadow-md dark:shadow-gray-700 border-2 border-gray-200 dark:border-gray-700 divide-x-2 dark:divide-gray-700 divide-primary-100 mt-6">
        <div className="z-[90] absolute top-4 right-5 grid justify-center gap-2">
          <div className="z-[2] grid place-items-center w-full bg-gray-100/70 dark:bg-black/10 dark:bg-gray-800/50 backdrop-blur-lg l rounded-xl shadow-lg dark:shadow-md dark:shadow-gray-700 border-2 border-gray-200 dark:border-gray-700 duration-75">
            <ThemeSelector />
          </div>
          <div className="z-[2] grid place-items-center w-full bg-gray-100/70 dark:bg-black/10 dark:bg-gray-800/50 backdrop-blur-lg l rounded-xl shadow-lg dark:shadow-md dark:shadow-gray-700 border-2 border-gray-200 dark:border-gray-700 duration-75">
            <LanguageSelector />
          </div>
        </div>
        {menu.map((item, index) => {
          return (
            <NavLink
              key={index}
              end={item?.end}
              to={item?.link || "#"}
              // onClick={window.innerWidth < 500 ? toggleSidebar : () => {}}
              className={({ isActive, isPending }) =>
                `grid place-items-center gap-0 px-4 py-3 duration-75 ${
                  isActive ? "text-primary" : "hover:text-primary"
                }`
              }
            >
              {item?.icon && (
                <i className={`fa fa-${item?.icon} text-lg mr-0 mb-1 `} />
              )}
              <span className="text-center text-xs font-semibold">
                {item?.name}
              </span>
            </NavLink>
          );
        })}
      </div>
      {/* <div className="flex w-full justify-center pt-3">
        <a
          target="_blank"
          href="https://www.senorjob.com"
          className="text-sm font-medium hover:text-primary"
        >
          © {new Date().getFullYear()}
        </a>
      </div> */}
    </div>
  );
}
