
export default function LoadingDots() {
  return (
    <div className="flex items-center gap-2">
      <div className="w-3 h-3 rounded-full animate-pulse bg-primary"></div>
      <div className="w-3 h-3 rounded-full animate-pulse bg-primary"></div>
      <div className="w-3 h-3 rounded-full animate-pulse bg-primary"></div>
    </div>
  );
}
