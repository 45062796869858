import React from "react";

const TextArea = React.forwardRef<HTMLButtonElement, any>(
  (
    {
      id,
      className,
      placeholder,
      type,
      value,
      name,
      onChange,
      disabled,
      autoComplete,
      cols,
      rows,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="">
        <textarea
          ref={ref}
          id={id}
          className={`w-full bg-gray-100 dark:bg-gray-800 rounded-lg shadow-lg p-3 ${className}`}
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          autoComplete={autoComplete}
          cols={cols}
          rows={rows}
          {...rest}
        />
      </div>
    );
  }
);

export default TextArea;
