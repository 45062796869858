import "./style.css";
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AppProvider } from "./context/AppContext";

import HomeLayout from "layouts/HomeLayout";
import { LanguageProvider } from "context/LanguageContext";
import Contact from "views/home/Contact";

const NotFound = React.lazy(() => import("views/NotFound"));
const Landing = React.lazy(() => import("views/home/Landing"));
const About = React.lazy(() => import("views/home/About"));
const Resume = React.lazy(() => import("views/home/Resume"));
const Portfolio = React.lazy(() => import("views/home/Portfolio"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "resume",
        element: <Resume />,
      },
      {
        path: "portfolio",
        element: <Portfolio />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  return (
    <LanguageProvider>
      <AppProvider>
        <div id="app">
          <div className="md:h-screen md:w-screen grid place-items-center bg-gray-200 dark:bg-gray-700 p-3 md:p-10 bg-[url('assets/images/bg.jpg')] dark:bg-[url('https://e0.pxfuel.com/wallpapers/1021/543/desktop-wallpaper-opera-r3-dark-theme-background.jpg')] bg-cover bg-center text-neutral font-poppins ">
            <div className="w-full h-full">
              <RouterProvider router={router} />
            </div>
          </div>
        </div>
      </AppProvider>
    </LanguageProvider>
  );
}

export default App;
