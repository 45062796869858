import Button from "components/ui/Button";
import Input from "components/ui/Input";
import { truncate } from "fs";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IForm {
  email: string;
}

export default function EmailAccess() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(
    localStorage.getItem("email") ? false : true
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<IForm>();
  const onSubmit: SubmitHandler<IForm> = async (data: any) => {
    console.log(data.email);
    setIsLoading(true);
    await fetch("https://coala.expertisnet.ma:5011/subscribe", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        email: data.email,
      }),
    })
      .then(() => {
        console.log("Email subscribed successfully !");
      })
      .catch((error) => {
        console.log("Error : ", error);
      })
      .finally(() => {
        data.email && localStorage.setItem("email", data.email);
        setIsVisible(false);
        setIsLoading(false);
      });
  };

  return (
    <>
      {isVisible && (
        <div className="z-[99] absolute w-full h-full backdrop-blur-md grid place-items-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-6 text-center bg-gray-100 dark:bg-gray-700 shadow-lg rounded-xl p-6"
          >
            <h1 className="font-semibold text-lg">WELCOME !</h1>
            <p className="">
              Would you please Enter your email <br /> to gain access to the app
              ...
            </p>
            <Input
              {...register("email", {
                required: "required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              })}
              type={"email"}
              name={"email"}
              placeholder={"name@example.com"}
            />
            <Button isAction type="submit" className="mx-auto">
              {!isLoading && <i className="fa fa-sign-in"></i>}
              {isLoading ? "Loading ..." : "Access"}
            </Button>
          </form>
        </div>
      )}
    </>
  );
}
