import { ChangeEvent, Suspense } from "react";
import { Outlet } from "react-router-dom";
import Loading from "../components/atoms/animations/LoadingSpin";
import Sidebar from "components/Sidebar";
import Hero from "components/molecules/Hero";
import Input from "components/ui/Input";
import Button from "components/ui/Button";
import EmailAccess from "components/molecules/EmailAccess";

export default function HomeLayout() {
  return (
    <div className="relative grid md:grid-cols-5 h-full w-full">
      <EmailAccess />
      <div className="mt-1 md:mt-0 md:absolute md:w-[85px] order-2">
        <Sidebar />
      </div>
      <div className="md:ml-[100px] md:col-span-2 z-20 grid place-items-center bg-gray-100/70 dark:bg-black/10 backdrop-blur-lg rounded-none shadow-lg dark:shadow-md dark:shadow-gray-700 border-2 border-gray-200 dark:border-gray-700 order-1 duration-75">
        <Hero />
      </div>
      <div className="md:col-span-3 min-h-[calc(100vh-7.9em)] mt-6 mb-3 md:my-6 md:-ml-10 md:pl-16 p-3 md:p-6 grid place-items-center bg-gray-100/70 dark:bg-black/10 backdrop-blur-lg rounded-xl shadow-lg dark:shadow-md dark:shadow-gray-700  border-2 border-gray-200 dark:border-gray-700 order-3 duration-75">
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
}
