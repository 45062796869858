import { useState, useEffect, createContext, FC, useContext } from "react";
import data_en from "context/data/data-hamza_en.json";
import data_fr from "context/data/data-hamza_fr.json";

import { LanguageContext } from "./LanguageContext";

export interface IAppContext {
  data: any;
  isLoading: boolean;
  setIsLoading?: (value: boolean) => void;
  theme: "light" | "dark";
  setTheme?: (value: "light" | "dark") => void;
}

const AppContext = createContext<Partial<IAppContext>>({});
export default AppContext;

export const AppProvider = ({ children }: any) => {
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState(data_en);
  useEffect(() => {
    console.log("Selected Language : ", language);
    switch (language) {
      case "en":
        setData(data_en);
        break;
      case "fr":
        setData(data_fr);
        break;
      default:
        setData(data_en);
    }
  }, [language]);

  const [theme, setTheme] = useState<"light" | "dark">(
    (localStorage.getItem("theme") as "light" | "dark") || "dark"
  );
  useEffect(() => {
    console.log("Selected Theme : ", theme);
    document.getElementById("app")?.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const [isLoading, setIsLoading] = useState<boolean>(false);


  return (
    <AppContext.Provider
      value={{
        data,
        isLoading,
        setIsLoading,
        theme,
        setTheme,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
